import * as React from 'react';
import styled from 'styled-components';
import { DateField } from '@mui/x-date-pickers';
import dayjs from 'dayjs';

import {
  UseFormRegister,
  UseFormSetValue,
  UseFormUnregister,
  UseFormWatch,
} from 'react-hook-form';

const Container = styled('div')`
  width: 100%;
  & label + .MuiInputBase-formControl {
    margin-top: 16px;
    border: 2px solid black;
    border-radius: 4px;
    height: 56px;
  }
  & .MuiFormControl-root {
    width: 100%;
  }
  & label {
    position: absolute;
    text-overflow: ellipsis;
    white-space: no-wrap;
    text-align: left;
    pointer-events: none;
    top: 25%;
    padding: 0px;
    left: 14px;
    line-height: 1;
    width: auto;
    font-weight: 400;
    transition: all 0.15s linear 0s;
    transform: translate3d(0px, -50%, 0px);
    background-color: white;
    color: black !important;
    z-index: 1;
    padding: 0px 4px;
    text-transform: uppercase;
    font-size: 0.75em;
  }
  }
  & .mobileDob {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  gap: 12px;
  margin-bottom: 24px;
  & > div {
    display: flex;
    flex-direction: column;
    flex: 1;
    & label {
      margin-bottom: 8px;
      width: 100%;
    }
    & label.hide {
      width: 1px;
      overflow: hidden;
      position: absolute;
      left: -9999px;
    }
  }
`;

const CURR_YEAR = new Date().getFullYear();
const MIN_AGE = 18;

export const formatDobFromDate = (dob: Date | string) => {
  let _dob: Date;
  if (typeof dob === 'string') {
    _dob = new Date(dob);
  } else {
    _dob = dob;
  }
  const year = _dob.getFullYear();
  let month: number | string = _dob.getMonth() + 1;
  let day: number | string = _dob.getDate();
  if (month < 10) {
    month = '0' + month;
  }
  if (day < 10) {
    day = '0' + day;
  }
  return [year, month, day].join('-');
};

export const formatDobString = ({ year, month, day }) => {
  let _month = month;
  let _day = day;
  if (month.length < 2) {
    _month = '0' + month;
  }
  if (day.length < 2) {
    _day = '0' + day;
  }
  return [year, _month, _day].join('-');
};

/**
 * @input Deals with JS date formats (January is 0)
 * @returns string formatted YYYY-MM-DD (January is 01)
 **/
export const DateInput = ({
  autoFocus,
  watch,
  setValue,
  label,
  required
}: {
  // This is from RHF's typing
  // eslint-disable-next-line
  autoFocus?: boolean;
  register: UseFormRegister<{ [x: string]: any }>;
  unregister: UseFormUnregister<{ [string: string]: string }>;
  // This is from RHF's typing
  // eslint-disable-next-line
  watch: UseFormWatch<{ [x: string]: any }>;
  // This is from RHF's typing
  // eslint-disable-next-line
  setValue: UseFormSetValue<{ [x: string]: any }>;
  defaultValue?: string;
  label?: string;
  required?: boolean;
}) => {
  // @ts-ignore
  const dob = `${label ? label.replaceAll(' ', '') + '_' : ''}dob`;

  const minAge = new Date(
    CURR_YEAR - MIN_AGE,
    new Date().getMonth(),
    new Date().getDate()
  );

  const watchedDob = watch(dob, null);
  return (
    <Container>
      <DateField
        required={required}
        autoFocus={autoFocus}
        label={label || 'Date of Birth'}
        format="MM/DD/YYYY"
        value={watchedDob ? dayjs(watchedDob) : null}
        maxDate={dayjs(minAge)}
        onChange={(date) => {
          if (!date) return;

          setValue(dob, date.toDate(), {
            shouldDirty: true,
          });
        }}
      />
    </Container>
  );
};
