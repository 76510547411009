import {
  FirebaseAuthentication,
  User,
} from '@capacitor-firebase/authentication';
import { Capacitor } from '@capacitor/core';

import {
  BadRequestError,
  NotFoundError,
  UnauthorizedError,
} from './error-types';
import { mapOnboardingToUserCreate } from '../utils/user.utils';
import { captureException } from '../analytics/sentry';
import { IOnboardingData } from '../providers/onboarding/onboarding.actions';
import { ROUTE_PATHS } from '../components/_pages/_utils/router.utils';
import { Goal, IRecommendationData } from '../components/Optimizer/types';
import { getUtmParams } from '../analytics/segment';
import {
  setFirebaseAnalyticsUserProperty,
  trackFirebaseEvent,
} from './firebase.service';
import { PLAN_PRICE } from '../utils';

export interface IUser {
  hasBureauAuthentication: boolean;
  bureauProviderRef: string;
  isSubscribed: string;
  source: string;
  email: string;
  employmentPayFrequency?: string;
  primaryPhone?: string;
  universityAttended?: string;
}

interface ICreateUserResponse {
  userId: string;
}

/**
 * Creates a user account in Array, which needs all of the onboarding data, but specifically:
 *  - last 4 of ssn, firstName, lastName, dob, address
 * @param data - full user onboarding data
 * @param userToken - Firebase auth token
 */
export const createUser = async (
  data: IOnboardingData,
  firebaseToken: string
): Promise<string> => {
  try {
    const createData = { ...data };
    if (!createData.source) delete createData.source;

    const response = await fetch(`${process.env.API_URL}/v1/borrowers`, {
      method: 'POST',
      headers: {
        'Access-Control-Allow-Origin': '*',
        'Content-Type': 'application/json',
        Authorization: `Bearer ${firebaseToken}`,
      },
      body: JSON.stringify(mapOnboardingToUserCreate(createData)),
    });

    let result;

    switch (response.status) {
      case 200:
        result = (await response.json()) as ICreateUserResponse;

        if (!result.borrowerId)
          throw new Error(
            'Failed to create user. Unexpected response from server'
          );

        return result.borrowerId;

      case 400:
        throw new BadRequestError(
          'Provided invalid onboarding data to user create'
        );
      case 401:
        throw new UnauthorizedError('Unauthorized');

      default:
        throw new Error('Failed to create user. Internal server error');
    }
  } catch (err) {
    captureException(err);

    throw err;
  }
};

/**
 * Get the full user data, including snapshots
 * @param userId - User ID in Firebase
 * @param userToken - Firebase auth token
 */
export const getUser = async (userId: string, userToken: string) => {
  const response = await fetch(`${process.env.API_URL}/v0/users/${userId}`, {
    method: 'GET',
    headers: {
      'Access-Control-Allow-Origin': '*',
      'Content-Type': 'application/json',
      Authorization: `Bearer ${userToken}`,
    },
  });

  switch (response.status) {
    case 200:
      return await response.json();

    case 404:
      return null;

    case 401:
      throw new UnauthorizedError('Unauthorized');

    default:
      throw new Error(
        `Failed to get user. Internal server error: ${response?.status}`
      );
  }
};

export const patchUser = async (payload: Partial<any>, firebaseUser: User) => {
  try {
    const response = await fetch(`${process.env.API_URL}/v0/users`, {
      method: 'PATCH',
      headers: {
        'Access-Control-Allow-Origin': '*',
        'Content-Type': 'application/json',
        Authorization: `Bearer ${
          (
            await FirebaseAuthentication.getIdToken()
          ).token
        }`,
      },
      body: JSON.stringify(payload),
    });

    switch (response.status) {
      case 200:
        return await response.json();

      case 404:
        throw new NotFoundError('User not found');

      default:
        throw new Error(
          `Failed to update user. Internal server error: ${response?.status}`
        );
    }
  } catch (err) {
    captureException(err);

    throw err;
  }
};

export const removeUser = async (firebaseRef: string, token: string) => {
  try {
    await fetch(`${process.env.API_URL}/v1/users`, {
      method: 'DELETE',
      headers: {
        'Access-Control-Allow-Origin': '*',
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`,
      },
    });
  } catch (err) {
    captureException(err);

    throw err;
  }
};

export type BureauSecurityAnswer = {
  id: string;
  text: string;
};

export type BureauSecurityQuestion = {
  id: string;
  text: string;
  answers: BureauSecurityAnswer[];
};

interface IUpdateCreditReport {
  firebaseUser: User;
}

/**
 * Authenticate the user by providing answers to security questions
 * Returns additional questions if the user is not fully authenticated
 * If authenticated, processes their credit report without returning any data
 *
 * @param data - { authToken, answers: { questionId, answerId }[] }
 * @param userId - User ID in Firebase
 * @param userToken - Firebase auth token
 */
export const updateCreditReport = async ({
  firebaseUser,
}: IUpdateCreditReport) => {
  const response = await fetch(
    `${process.env.API_URL}/v1/borrowers/${firebaseUser.uid}/bureau-provider/update-snapshot`,
    {
      method: 'POST',
      headers: {
        'Access-Control-Allow-Origin': '*',
        'Content-Type': 'application/json',
        Authorization: `Bearer ${
          (
            await FirebaseAuthentication.getIdToken()
          ).token
        }`,
      },
    }
  );

  switch (response.status) {
    case 200:
      return;
    case 404:
      throw new NotFoundError(
        'Failed to request credit report. User not found'
      );

    default:
      throw new Error('Unknown error occured while requesting credit report');
  }
};

export type StripeLineItem = {
  price: string;
  quantity: number;
};

export const STRIPE_FREE_ITEM = {
  price: process.env.STRIPE_FREE_ITEM || 'price_1PTmMeC4VcGzfkjKVoW5K1m1',
  quantity: 1,
  label: 'Solve Finance (via Debt Optimizer)',
  amount: 0.0,
};

export const STRIPE_MONTHLY_ITEM = {
  price: process.env.STRIPE_MONTHLY_ITEM || 'price_1Q5FbTC4VcGzfkjKEBUlsoIz',
  quantity: 1,
  label: 'Solve Finance (via Debt Optimizer)',
  amount: PLAN_PRICE,
};

/**
 *
 */
export const getStripeCheckout = async ({
  firebaseRef,
  token,
  lineItems,
}): Promise<{ url: string | null; subscriptionId: string | null }> => {
  const response = await fetch(
    `${process.env.API_URL}/v0/users/${firebaseRef}/checkout`,
    {
      method: 'POST',
      headers: {
        'Access-Control-Allow-Origin': '*',
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`,
      },
      body: JSON.stringify({
        line_items: lineItems,
        mode: 'subscription',
        from_app: Capacitor.getPlatform() !== 'web' ? 'true' : 'false',
        local: process.env.BASE_URL,
      }),
    }
  );

  switch (response.status) {
    case 200:
      const result = await response.json();
      const url = result?.success
        ? result?.url
        : ROUTE_PATHS.SUBSCRIPTION + '?status=error';

      return { url, subscriptionId: result?.subscriptionId };
    case 400:
      throw new BadRequestError(
        'Invalid request made to Stripe checkout setup'
      );
    default:
  }
};

export const getStripeSubscriptionPaymentIntent = async ({
  firebaseRef,
  token,
  lineItems,
}): Promise<{
  paymentIntentClientSecret?: string;
  customerId: string;
  subscriptionId: string;
  ephemeralKey: string;
}> => {
  const response = await fetch(
    `${process.env.API_URL}/v1/users/${firebaseRef}/create-subscription`,
    {
      method: 'POST',
      headers: {
        'Access-Control-Allow-Origin': '*',
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`,
      },
      body: JSON.stringify({
        line_items: lineItems,
        mode: 'subscription',
        from_app: Capacitor.getPlatform() !== 'web' ? 'true' : 'false',
      }),
    }
  );

  switch (response.status) {
    case 200:
      return response.json();
    case 400:
      throw new BadRequestError(
        'Invalid request made to Stripe payment intent setup'
      );
    default:
  }
};

interface IRequestUserRecommendationsResult {
  recommendationId: string;
  recommendations: IRecommendationData[];
  timestamp: string;
  totalEstimatedSavings: number;
  scoreUsed?: number;
}

interface IRequestUserRecommendations {
  firebaseUser: User;
  goal?: Goal;
  scoreUsed?: number;
}

export const requestUserRecommendations = async ({
  firebaseUser,
  goal = 'MIN_MONTHLY',
  scoreUsed,
}) => {
  try {
    const queryString = new URLSearchParams({ goal }).toString();
    const response = await fetch(
      `${process.env.API_URL}/v0/users/${
        firebaseUser.uid
      }/recommendation?${queryString}${
        scoreUsed ? `&scoreUsed=${scoreUsed}` : ''
      }`,
      {
        method: 'GET',
        headers: {
          'Access-Control-Allow-Origin': '*',
          'Content-Type': 'application/json',
          Accept: 'application/json',
          Authorization: `Bearer ${
            (
              await FirebaseAuthentication.getIdToken()
            ).token
          }`,
        },
      }
    );

    switch (response.status) {
      case 200:
        return await response.json();
      case 204:
        return [];
      default:
        throw new Error(
          'Unknown error occurred when requesting recommendations'
        );
    }
  } catch (err) {
    captureException(err);
    throw err;
  }
};

let eventQueue = [];

export const processEventQueue = async (firebaseUser) => {
  await recordEvent({ eventName: null, firebaseUser, props: null });
};

interface IRecordEvent {
  eventName: string;
  firebaseUser: User;
  props?: any;
}

export const recordEvent = async ({
  eventName,
  firebaseUser,
  props,
}: IRecordEvent) => {
  if (eventName) eventQueue.push({ eventName, props: props || {} });

  try {
    if (firebaseUser) {
      const currQueue = [...eventQueue];
      eventQueue = [];

      const token = (await FirebaseAuthentication.getIdToken()).token;
      const params = await getUtmParams();

      for (let i = 0; i < currQueue.length; i++) {
        await trackFirebaseEvent(
          currQueue[i].eventName,
          currQueue[i].props || {}
        );

        await fetch(`${process.env.API_URL}/v1/context`, {
          method: 'POST',
          body: JSON.stringify({
            name: currQueue[i].eventName,
            properties: currQueue[i].props,
            params,
          }),
          headers: {
            'Access-Control-Allow-Origin': '*',
            'Content-Type': 'application/json',
            Accept: 'application/json',
            Authorization: `Bearer ${token}`,
          },
        });
      }
    }
  } catch (err) {
    captureException(err);
    // Fail silently...
  }
};

interface IUpdateIdentity {
  params?: any;
  goal?: string;
  platform?: string;
  viewport?: string;
}

export const updateIdentity = async ({
  params,
  goal,
  platform,
  viewport,
}: IUpdateIdentity) => {
  try {
    const token = (await FirebaseAuthentication.getIdToken()).token;

    if (platform) await setFirebaseAnalyticsUserProperty('platform', platform);
    if (viewport) await setFirebaseAnalyticsUserProperty('viewport', viewport);
    if (goal) await setFirebaseAnalyticsUserProperty('goal', goal);

    await fetch(`${process.env.API_URL}/v1/identity`, {
      method: 'POST',
      body: JSON.stringify({ params, platform, viewport, goal }),
      headers: {
        'Access-Control-Allow-Origin': '*',
        'Content-Type': 'application/json',
        Accept: 'application/json',
        Authorization: `Bearer ${token}`,
      },
    });
  } catch (err) {
    captureException(err);
    // Fail silently...
  }
};
