import React from 'react';
import styled, { css } from 'styled-components';
import { Link, useLocation, useNavigate } from 'react-router-dom';

import BetterContact from './BetterContact';
import { theme } from '../../../styles/GlobalStyle';
import { Row } from '../../../../components/page-components-copy/components';
import { ROUTE_PATHS } from '../../../../components/_pages/_utils/router.utils';

import { NavNode, TNavNodeChild } from './NavNode';
import { useScrollPosition } from '../../../../hooks/useScrollPosition';
import ButtonStyled from '../../../Button';
import { useAuthContext } from '../../../../providers/auth.provider';
import { useWindowWidth } from '../../../../hooks/useWindowSize';
import { IMAGES, getImage, handleTap } from '../../../../utils';

const logo = getImage(IMAGES.SOLVE_LOGO_BLACK_TEXT.ref);

/**
 * Styled Links
 */
export const NavLink = styled(Link)<{ to: string; currentPage?: string }>`
   font-weight: bold;
   font-family: 'Majorant-Regular', Helvetica;
   font-size: 1rem;
   font-weight: 400;
   white-space: nowrap;
   text-decoration: none;
   margin: auto;
   margin-right: 2rem;
   color: ${({ theme }) => theme.gray7} !important;

   &:hover {
   &::after {
    display: block;
    position: absolute;
    content: '';
    width: 100%;
    height: 3px;
    background-color: ${({ theme }) => theme.blue4};
    left: 0;
    bottom: -2px;
  }
}

   ${({ to, currentPage }) =>
     (currentPage || '').startsWith(to) &&
     css`
       &&& {
         text-decoration: underline;
       }
     `}
 }`;

const Nav = styled('nav')<{ scrolled: boolean }>`
  box-shadow: ${({ scrolled }) =>
    scrolled ? '0px 4px 4px rgba(0, 0, 0, 0.1)' : 'none'};
`;

/**
 * Links to render
 */
const leftHeaderLinks = [
  [
    'link',
    '/',
    undefined,
    undefined,
    <img
      src={logo}
      style={{ width: '113px', marginRight: '32px' }}
      key={'logo'}
    />,
  ],
  [
    'nested',
    undefined,
    'Solutions',
    'By User Type',
    [
      {
        to: 'https://www.solve.finance/financial-advisor-for-your-users/',
        title: 'Partners',
        subtext: 'Enable easier borrowing for your users',
      },
      {
        to: 'https://www.solve.finance/financial-advisor-for-individuals/',
        title: 'Individuals',
        subtext: 'Sign up to use our Debt Optimizer',
      },
    ],
  ],
  [
    'nested',
    undefined,
    'Tools',
    undefined,
    [
      {
        to: 'https://app.solve.finance',
        title: 'Debt Optimizer',
        subtext: 'Put better borrowing on automatic with our full service app',
      },
      {
        to: 'https://www.solve.finance/mortgage-dti-calculator',
        title: 'DTI Calculator',
        subtext: 'Free: quickly calculate your debt-to-income ratio',
      },
      {
        to: 'https://solve.finance/how-much-house-can-i-afford-calculator',
        title: 'Home Affordability Maximizer',
        subtext: 'Free: estimate your home-buying power',
      },
      {
        to: 'https://solve.finance/how-to-borrow-online/',
        title: 'Borrow Online',
        subtext: 'Free: how to borrow online quickly',
      },
      {
        to: 'https://www.solve.finance/independent-mortgage-advice',
        title: 'Home Finance',
        subtext: 'Free: independent mortgage calculators',
      },
      {
        to: 'https://www.solve.finance/how-to-borrow-against-your-house/',
        title: 'Borrow From Your House',
        subtext: 'Free: how do you get equity out of your home?',
      },
      {
        to: 'https://www.solve.finance/how-to-improve-your-credit-score',
        title: 'Improve Credit Score',
        subtext: 'Free: how to make your credit score better',
      },
    ],
  ],
  ['link', '/pricing', undefined, undefined, 'Pricing'],
  [
    'nested',
    undefined,
    'Company',
    undefined,
    [
      {
        to: 'https://www.solve.finance/about-solve-finance',
        title: 'About',
      },
      {
        to: 'https://apply.workable.com/solve-finance/?lng=en',
        title: 'Careers',
      },
    ],
  ],
  [
    'link',
    'https://www.solve.finance/resources/',
    undefined,
    undefined,
    'Resources',
  ],
] as [
  type: string,
  to: string | undefined,
  title: string | undefined,
  subtext: string | undefined,
  nodes: TNavNodeChild[]
][];

const SignOut = styled(ButtonStyled)`
  height: 40px;
  width: 130px !important;
  margin: 13px 32px !important;
`;

/**
 * The header navigation bar.
 */
const Header: React.FC<{
  currentPage: string;
  handleSignOut: () => Promise<void>;
  className?: string;
  disableAccountLinks?: boolean;
}> = ({ handleSignOut, className, disableAccountLinks }) => {
  const { handleSignUp } = useAuthContext();
  const { width } = useWindowWidth();
  const { y: scrollY } = useScrollPosition();
  const { pathname, search } = useLocation();
  const navigate = useNavigate();
  const params = new URLSearchParams(search);
  const source =
    params.get('utm_source') || localStorage.getItem('solve_source');

  const hideAuthRow =
    disableAccountLinks ||
    pathname.includes(ROUTE_PATHS.ONBOARDING) ||
    pathname.includes(ROUTE_PATHS.DASHBOARD) ||
    pathname === ROUTE_PATHS.BETTER ||
    source === 'better';

  const hideResources = width < 810;
  const hideGetStarted = width < 933;

  const isExternal = (url) => url.startsWith('https');
  return (
    <Nav className={className} scrolled={scrollY > 0}>
      <div id="header-content" role="menubar">
        <div>
          {leftHeaderLinks.map(([type, to, title, subtext, nodes], index) => {
            if (hideResources && String(nodes) === 'Resources') {
              return null;
            }

            return type === 'nested' ? (
              <NavNode
                title={title}
                subtext={subtext}
                nodes={nodes}
                key={`${title}-${index}}`}
              />
            ) : (
              <NavLink
                as={isExternal(to) ? 'a' : undefined}
                to={isExternal(to) ? undefined : to}
                href={isExternal(to) ? to : undefined}
                key={`${to}-${index}}`}
                role="menuitem"
                target={isExternal(to) ? '_blank' : undefined}
                rel={isExternal(to) ? 'noreferrer noopener' : undefined}
              >
                {nodes}
              </NavLink>
            );
          })}
          {(pathname === ROUTE_PATHS.BETTER ||
            (source === 'better' && pathname === '/')) &&
            hideAuthRow && <BetterContact />}
        </div>
        {!hideAuthRow && (
          <Row className="auth-row">
            <Link to={ROUTE_PATHS.LOGIN}>Log In</Link>
            {!hideGetStarted && (
              <ButtonStyled
                style={{
                  borderRadius: 8,
                  fontSize: 14,
                  height: '32px',
                  minHeight: '32px',
                  width: '110px',
                  minWidth: '110px',
                  color: theme.white,
                  backgroundColor: theme.blue4,
                  border: `2px solid ${theme.blue4}`,
                  padding: 0,
                  marginRight: '12px',
                  textTransform: 'none',
                  whiteSpace: 'nowrap',
                }}
                onClick={() => {
                  handleSignUp();

                  navigate(ROUTE_PATHS.ONBOARDING);
                }}
              >
                Get Started
              </ButtonStyled>
            )}
          </Row>
        )}
      </div>
    </Nav>
  );
};

/**
 * The Final Exported Container
 */
const StyledHeader = styled(Header)`
  position: fixed;
  top: 0;
  background-color: #fff;
  display: flex;
  width: 100%;
  justify-content: center;
  z-index: 1;
  height: 68px;

  &.scrolled {
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.1);
  }

  @media print {
    display: none;
  }

  #header-content {
    display: flex;
    max-width: 1200px;
    height: calc(env(safe-area-inset-top) + 69px);
    justify-content: space-between;
    flex-wrap: wrap;
    width: 100%;
  }
  @media screen and (max-width: 480px) {
    #header-content > div > ul {
      display: none !important;
    }
    #header-content div > button {
      display: none !important;
    }
  }

  @media screen and (max-width: 1200px) {
    #header-content {
      padding: 0 32px;
    }
  }

  #header-content > div:nth-child(1) {
    display: flex;
    justify-content: flex-start;
  }

  #header-content > div:nth-child(2) {
    display: flex;
    justify-content: center;
    align-items: center;
  }

  #header-content > div > ul,
  #header-content > div > a {
    position: relative;
    display: flex;
    margin-right: 24px;
    align-items: center;
    cursor: pointer;
  }
  #header-content > div > ul > svg {
    width: 24px;
  }
  #header-content > div > a > svg {
    margin-right: 32px;
    width: 113px;
  }
  & .auth-row {
    a,
    a:hover,
    a:visited,
    a:active,
    a:focus {
      color: ${({ theme }) => theme.gray7};
    }
  }
`;

export default StyledHeader;
